/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {
    type EntityFilterQuery,
    CATALOG_FILTER_EXISTS,
} from '@backstage/catalog-client';
import {
    Entity,
    parseEntityRef,
    stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
    EntityDisplayName,
    EntityRefPresentationSnapshot,
    catalogApiRef,
    entityPresentationApiRef,
} from '@backstage/plugin-catalog-react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
    AutocompleteChangeReason,
    createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect } from 'react';
import useAsync from 'react-use/esm/useAsync';

import { VirtualizedListbox } from '../VirtualizedListbox';
import { isEntityPickerProps, isExistsCheckingFilter } from './Guards';
import { CatalogFilter, CatalogFilterQuery, CatalogFilterQueryValue, EntityPickerProps, UiOptions } from './Schema';
import { CatalogFilterBuilder, CatalogFilterEntryBuilder, EntityPickerConstruction } from './Types';
import { FieldExtensionUiSchema } from '@backstage/plugin-scaffolder-react';


export function createEntityPicker<TUiSchema extends FieldExtensionUiSchema<string, TUiOptions>, TUiOptions extends UiOptions>
    (input: EntityPickerConstruction<TUiSchema, string, TUiOptions>): (props: EntityPickerProps<string, TUiOptions>) => React.JSX.Element {
    let {
        catalogFilterBuilder,
        catalogFilterEntryBuilder,
        entityFilter,
        propsAdapter
    } = input;    

    return (props: EntityPickerProps<string, TUiOptions>) => {
        let adaptedResult = null;
        if (propsAdapter != null) {
            adaptedResult = propsAdapter(props);
            if (isEntityPickerProps(adaptedResult)) {
                props = adaptedResult;
            }
        }

        const {
            onChange,
            schema: {
                title = "Select entity",
                description = "Select an entity from the catalog",
            },
            required,
            uiSchema,
            rawErrors,
            formData,
            formContext,
            idSchema,
        } = props;

        entityFilter = entityFilter ?? noItemFiltering;
        catalogFilterEntryBuilder = catalogFilterEntryBuilder ?? convertOpsValues;
        catalogFilterBuilder = catalogFilterBuilder ?? buildCatalogFilter(catalogFilterEntryBuilder!);

        const activeUiSchema = uiSchema as TUiSchema;
        if (activeUiSchema == null)
            throw new Error("Ui Schema is of the wrong type: " + typeof activeUiSchema)

        const catalogFilter = catalogFilterBuilder == null ?
            buildCatalogFilter(catalogFilterEntryBuilder!)(activeUiSchema, formContext.formData) :
            catalogFilterBuilder(activeUiSchema, formContext.formData);
        const defaultKind = activeUiSchema['ui:options']?.defaultKind;
        const defaultNamespace =
            activeUiSchema['ui:options']?.defaultNamespace || undefined;

        const catalogApi = useApi(catalogApiRef);
        const entityPresentationApi = useApi(entityPresentationApiRef);

        const { value: entities, loading } = useAsync(async () => {
            const fields = [
                'metadata.name',
                'metadata.namespace',
                'metadata.title',
                'kind',
            ];
            const { items } = await catalogApi.getEntities(
                catalogFilter
                    ? { filter: catalogFilter, fields }
                    : { filter: undefined, fields },
            );

            const filteredItems = entityFilter == undefined ?
                noItemFiltering(items) :
                entityFilter(items);

            const entityRefToPresentation = new Map<
                string,
                EntityRefPresentationSnapshot
            >(
                await Promise.all(
                    filteredItems.map(async item => {
                        const presentation = await entityPresentationApi.forEntity(item)
                            .promise;
                        return [stringifyEntityRef(item), presentation] as [
                            string,
                            EntityRefPresentationSnapshot,
                        ];
                    }),
                ),
            );

            return { catalogEntities: items, entityRefToPresentation };
        }, [catalogApi, catalogFilter, entityFilter, entityPresentationApi, stringifyEntityRef]);

        const allowArbitraryValues =
            activeUiSchema['ui:options']?.allowArbitraryValues ?? true;

        const getLabel = useCallback(
            (freeSoloValue: string) => {
                try {
                    // Will throw if defaultKind or defaultNamespace are not set
                    const parsedRef = parseEntityRef(freeSoloValue, {
                        defaultKind,
                        defaultNamespace,
                    });

                    return stringifyEntityRef(parsedRef);
                } catch (err) {
                    return freeSoloValue;
                }
            },
            [defaultKind, defaultNamespace],
        );

        const onSelect = useCallback(
            (_: any, ref: string | Entity | null, reason: AutocompleteChangeReason) => {
                // ref can either be a string from free solo entry or
                if (typeof ref !== 'string') {
                    // if ref does not exist: pass 'undefined' to trigger validation for required value
                    onChange(ref ? stringifyEntityRef(ref as Entity) : undefined);
                } else {
                    if (reason === 'blur' || reason === 'create-option') {
                        // Add in default namespace, etc.
                        let entityRef = ref;
                        try {
                            // Attempt to parse the entity ref into it's full form.
                            entityRef = stringifyEntityRef(
                                parseEntityRef(ref as string, {
                                    defaultKind,
                                    defaultNamespace,
                                }),
                            );
                        } catch (err) {
                            // If the passed in value isn't an entity ref, do nothing.
                        }
                        // We need to check against formData here as that's the previous value for this field.
                        if (formData !== ref || allowArbitraryValues) {
                            onChange(entityRef);
                        }
                    }
                }
            },
            [onChange, formData, defaultKind, defaultNamespace, allowArbitraryValues],
        );

        // Since free solo can be enabled, attempt to parse as a full entity ref first, then fall
        // back to the given value.
        const selectedEntity =
            entities?.catalogEntities.find(e => stringifyEntityRef(e) === formData) ??
            (allowArbitraryValues && formData ? getLabel(formData) : '');

        useEffect(() => {
            if (entities?.catalogEntities.length === 1 && selectedEntity === '') {
                onChange(stringifyEntityRef(entities.catalogEntities[0]));
            } else if (entities?.catalogEntities.length === 0) {
                onChange(undefined)
            }
        }, [entities, onChange, selectedEntity]);

        //We need to register all hooks above to prevent missing hooks during loading for example
        //So once we processed everything, is when we unwravel the adapted result, and check if we have a control
        //If we do we return that instead of our auto-complete.
        if (adaptedResult != null && !isEntityPickerProps(adaptedResult))
            return adaptedResult;

        return (
            <FormControl
                margin="normal"
                required={required}
                error={rawErrors?.length > 0 && !formData}
            >
                <Autocomplete
                    disabled={entities?.catalogEntities.length === 1}
                    id={idSchema?.$id}
                    value={selectedEntity}
                    loading={loading}
                    onChange={onSelect}
                    options={entities?.catalogEntities || []}
                    getOptionLabel={option =>
                        // option can be a string due to freeSolo.
                        typeof option === 'string'
                            ? option
                            : entities?.entityRefToPresentation.get(stringifyEntityRef(option))
                                ?.entityRef!
                    }
                    autoSelect
                    freeSolo={allowArbitraryValues}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={title}
                            margin="dense"
                            helperText={description}
                            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
                            variant="outlined"
                            required={required}
                            InputProps={params.InputProps}
                        />
                    )}
                    renderOption={option => <EntityDisplayName entityRef={option} />}
                    filterOptions={createFilterOptions<Entity>({
                        stringify: option =>
                            entities?.entityRefToPresentation.get(stringifyEntityRef(option))
                                ?.primaryTitle!,
                    })}
                    ListboxComponent={VirtualizedListbox}
                />
            </FormControl>
        );
    };
}

/**
 * This unique symbol can be used to remove a specific filter from the catalog filter chain.
 */
export const CATALOG_FILTER_REMOVE_FROM_FILTER: unique symbol = Symbol();

/**
 * Converts a special `{exists: true}` value to the `CATALOG_FILTER_EXISTS` symbol.
 * Converts a special `{parameter: <something> }` value to a catalog filter entry with the
 * value from a different parameter in the same form, without 
 *
 * @param value - The value to convert.
 * @returns The converted value.
 */
export function convertOpsValues(
    value: Exclude<CatalogFilterQueryValue, Array<any>>,
    _formData: any
): string | symbol {
    if (isExistsCheckingFilter(value) && value.exists) {
        //If value.exists is false, we just return the stringified object, a bit of a hack to be honest, but that is what 
        //BS does as well and it works natively.
        return CATALOG_FILTER_EXISTS;
    }

    return value?.toString() ?? CATALOG_FILTER_REMOVE_FROM_FILTER;
}

/**
 * Converts schema filters to entity filter query, replacing `{exists:true}` values
 * with the constant `CATALOG_FILTER_EXISTS`.
 *
 * @param schemaFilters - An object containing schema filters with keys as filter names
 * and values as filter values.
 * @parma formContext - The context of object of the form the picker resides in.
 * @returns An object with the same keys as the input object, but with `{exists:true}` values
 * transformed to `CATALOG_FILTER_EXISTS` symbol.
 */
function convertSchemaFiltersToQuery(
    schemaFilters: CatalogFilterQuery,
    formData: any,
    entryProcessor: CatalogFilterEntryBuilder
): Exclude<EntityFilterQuery, Array<any>> {
    const query: EntityFilterQuery = {};

    for (const [key, value] of Object.entries(schemaFilters)) {
        if (Array.isArray(value)) {
            query[key] = value;
        } else {
            query[key] = entryProcessor(value, formData);
            if (query[key] === CATALOG_FILTER_REMOVE_FROM_FILTER) {
                query[key] == undefined;
            }
        }
    }

    return query;
}

/**
 * An item filter which does not perform any filter at all.
 * Used as the default item filter implementation.
 *  
 * @param items The input items of the filter.
 * @returns The input items.
 */
export function noItemFiltering(items: Entity[]): Entity[] {
    return items;
}


/**
 * Builds an `EntityFilterQuery` based on the `uiSchema` passed in.
 * If `catalogFilter` is specified in the `uiSchema`, it is converted to a `EntityFilterQuery`.
 * If `allowedKinds` is specified in the `uiSchema` will support the legacy `allowedKinds` option.
 *
 * @param uiSchema The `uiSchema` of an `EntityPicker` component.
 * @returns An `EntityFilterQuery` based on the `uiSchema`, or `undefined` if `catalogFilter` is not specified in the `uiSchema`.
 */
export function buildCatalogFilter<TUiSchema extends FieldExtensionUiSchema<string, TUiOptions>, TUiOptions extends UiOptions>(
    entryProcessor: CatalogFilterEntryBuilder
): CatalogFilterBuilder<TUiSchema, string, TUiOptions> {
    return (uiSchema, formData) => {
        const uiOptions = uiSchema['ui:options'];
        const allowedKinds = uiOptions?.allowedKinds;

        const catalogFilter: CatalogFilter =
            uiOptions?.catalogFilter ||
            (allowedKinds && { kind: allowedKinds });

        if (!catalogFilter) {
            return undefined;
        }

        if (Array.isArray(catalogFilter)) {
            return catalogFilter.map(entry => convertSchemaFiltersToQuery(entry, formData, entryProcessor));
        }

        return convertSchemaFiltersToQuery(catalogFilter, formData, entryProcessor);
    };
}