import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const yamlActionTestPlugin = createPlugin({
  id: 'yaml-action-test',
  routes: {
    root: rootRouteRef,
  },
});

export const YamlActionTestPage = yamlActionTestPlugin.provide(
  createRoutableExtension({
    name: 'YamlActionTestPage',
    component: () =>
      import('./components/RootComponent').then(m => m.RootComponent),
    mountPoint: rootRouteRef,
  }),
);
