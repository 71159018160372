import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { LbEntityPicker } from './LbEntityPicker';
import { LbEntityPickerSchema } from './Schema';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';

export * from './LbEntityPicker';
export * from './Schema';

export const LbEntityPickerFieldExtension = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
        name: 'LbEntityPicker',
        component: LbEntityPicker,
        schema: LbEntityPickerSchema
    })
)