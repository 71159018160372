import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { AzureDevOpsSelector } from './components/AzureDevOpsField';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';

export const LbAzureDevopsFrontendPage = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureDevOpsSelector',
    component: AzureDevOpsSelector,
  })
);
