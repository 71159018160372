import React, { useState } from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { AzureDevOpsProject } from '@internal/backstage-plugin-lb-azure-devops-backend/src/service/action';

/** Default empty project list */
const defaultProjectList: Array<AzureDevOpsProject> = [];

/*
 This is the actual component that will get rendered in the form
*/
export const AzureDevOpsSelector = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {
  const config = useApi(configApiRef);
  const { fetch } = useApi(fetchApiRef);
  const backendUrl = config.getString('backend.baseUrl');

  const [projectlist, setProjectlist] = useState({
    lastChange:  '',
    list: defaultProjectList,
    value: ''
  });

  if (projectlist.list.length == 0) {
    console.log('Fetch projects');
    fetch(`${backendUrl}/api/lbAzureDevopsPlugin/projects`, {
      method: 'GET',
      headers: [['Content-Type', 'application/json; charset=utf-8']],
    })
      .then(response => response.json())
      .then(payload => {
        setProjectlist({
          lastChange: new Date().toUTCString(),
          list: payload.sort((a:AzureDevOpsProject, b:AzureDevOpsProject) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())),
          value: 'noneSelected'
        });
      });
  }

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <InputLabel id="devopsSelectorLabel">Project</InputLabel>
      <Select
        labelId="devopsSelectorLabel"
        id="devopsSelector"
        onClick={(e) => { setProjectlist({
          lastChange: new Date().toUTCString(),
          list: projectlist.list,
          value: (e.target as any).value});
          onChange((e.target as any).value);
        }}
        value={projectlist.value}
        key={projectlist.value}
      >
        {projectlist.list.map(devopsProject => {
          return (
            <MenuItem value={devopsProject.id} key={devopsProject.id}>{devopsProject.name}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
