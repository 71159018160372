import { RELATION_OWNED_BY } from '@backstage/catalog-model';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import useAsync from 'react-use/esm/useAsync';
import { UiOptions } from '../AbstractEntityPicker';
import { createLbEntityPicker, LbEntityPickerProps, LbEntityPickerUiSchema } from '../LbEntityPicker';

/**
 * The underlying component that is rendered in the form for the `EntityPicker`
 * field extension.
 *
 * @public
 */
export const LbOwnedEntityPicker = createLbEntityPicker( {
    propsAdapter : onCreateEntityPicker
});

function onCreateEntityPicker(props: LbEntityPickerProps): React.JSX.Element | LbEntityPickerProps {
    const {
        schema: {
            title = "Your entity",
            description = "Select your entity.",
        },
        uiSchema,
        required,
    } = props;

    const identityApi = useApi(identityApiRef);
    const { loading, value: identityRefs } = useAsync(async () => {
        const identity = await identityApi.getBackstageIdentity();
        return identity.ownershipEntityRefs;
    });

    if (loading)
        //We are loading, so we return a custom element to prevent the normal picker logic from running.
        return (
            <Autocomplete
                loading={loading}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={title}
                        margin="dense"
                        helperText={description}
                        FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
                        variant="outlined"
                        required={required}
                        InputProps={params.InputProps}
                    />
                )}
                options={[]}
            />
        );

    //We are not loading our API access anymore
    //Process the entity picker UI schema    
    const newUiSchema = buildEntityPickerUISchema(
        uiSchema,
        identityRefs
    )

    //Normal further processing, overwriting uiSchema
    return {
        ...props,
        uiSchema: newUiSchema
    };
}


/**
 * Builds a `uiSchema` for an `EntityPicker` from a parent `OwnedEntityPicker`.
 * Migrates deprecated parameters such as `allowedKinds` to `catalogFilter` structure.
 *
 * @param uiSchema The `uiSchema` of an `OwnedEntityPicker` component.
 * @param identityRefs The user and group entities that the user claims ownership through.
 * @returns The `uiSchema` for an `EntityPicker` component.
 */
function buildEntityPickerUISchema(
    uiSchema: LbEntityPickerUiSchema,
    identityRefs: string[] | undefined,
): LbEntityPickerUiSchema {
    const uiOptions: UiOptions =
        uiSchema?.['ui:options'] || {};

    //Deconstruct the existing ui options, so that we get all others, minus the allowed kinds, since we process them seperately.
    const { allowedKinds, ...extraOptions } = uiOptions;

    const catalogFilter = asArray(uiOptions.catalogFilter).map(filterEntry => {
        //Now first deconstruct the entry, then overwrite with the allowed kinds if set, and then overwrite again with the relations filter.
        return ({
            ...filterEntry,
            ...(allowedKinds ? { kind: allowedKinds } : {}),
            [`relations.${RELATION_OWNED_BY}`]: identityRefs || [],
        });
    });

    //Reconstruct a valid ui options from the old extra options, and the new catalog filter
    //Stripping the allowed kinds processing since we already did that.
    return {
        'ui:options': {
            ...extraOptions,
            catalogFilter,
        },
    };
}

function asArray(catalogFilter: any): any[] {
    if (catalogFilter) {
        return Array.isArray(catalogFilter) ? catalogFilter : [catalogFilter];
    }
    return [{}];
}