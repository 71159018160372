import { BackstageOverrides } from '@backstage/core-components';
import { BackstageOverrides as CatalogReactOverrides } from '@backstage/plugin-catalog-react';
import { BackstageTheme, createTheme, genPageTheme, shapes, themes } from '@backstage/theme';

/**
 * Base color theme
 */
const lbBaseTheme = createTheme({
    palette: {
        ...themes.dark.getTheme('v5')!.palette,
        background: {
            default: '#3B3B3B',
            paper: '#2D3D4B'
        },
        primary: {
            main: '#E398AC',
        },
        secondary: {
            main: '#FCBC67',
        },
    },
    pageTheme: {
        home: genPageTheme({ colors: ['#334747', '#85B4A9'], shape: shapes.wave }),
    },
    fontFamily: 'Officina Sans ITC Pro, sans serif, Arial',
    defaultPageTheme: 'home',
});

/**
 * Images
 */
const createCustomThemeOverrides = (
): BackstageOverrides & CatalogReactOverrides => {
    return {
        BackstagePage: {
            root: {
                backgroundImage: 'url(/background-lb.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }
        }
    }
};

/**
 * LichtBlick theme
 */
export const lbTheme: BackstageTheme = {
    ...lbBaseTheme,
    overrides: {
        ...lbBaseTheme.overrides,
        ...createCustomThemeOverrides(),
    },
};
