import React from "react";
import { CatalogFilterQueryValue, EntityPickerProps, ExistsCheckingFilter, UiOptions } from "./Schema";

export function isExistsCheckingFilter(filter : CatalogFilterQueryValue): filter is ExistsCheckingFilter {
    return (filter as ExistsCheckingFilter).exists !== undefined;
}

export function isEntityPickerProps<TUiOptions extends UiOptions>(filter: EntityPickerProps<string, TUiOptions> | React.JSX.Element): filter is EntityPickerProps<string, TUiOptions> {
    return (filter as EntityPickerProps<string, TUiOptions>).uiSchema !== undefined
}
