import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { LbOwnedEntityPicker } from './LbOwnedEntityPicker';
import { LbEntityPickerSchema } from '../LbEntityPicker';

export * from './LbOwnedEntityPicker';

export const LbOwnedEntityPickerFieldExtension = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
        name: 'LbOwnedEntityPicker',
        component: LbOwnedEntityPicker,
        schema: LbEntityPickerSchema
    })
)