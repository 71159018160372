import { CatalogFilterQueryValue, createEntityPicker, EntityPickerConstruction, EntityPickerProps, convertOpsValues as superConvertOps } from '../AbstractEntityPicker';
import { isParameterReferenceFilter } from './Guards';
import {
    LbEntityPickerUiOptions,
    LbEntityPickerUiSchema
} from './Schema';

 
/**
 * The underlying component that is rendered in the form for the `EntityPicker`
 * field extension.
 *
 * @public
 */
export const LbEntityPicker = createLbEntityPicker();

export function createLbEntityPicker(props: EntityPickerConstruction<LbEntityPickerUiSchema, string, LbEntityPickerUiOptions> = {}) : (props: EntityPickerProps<string, LbEntityPickerUiOptions>) => React.JSX.Element {
    return createEntityPicker<LbEntityPickerUiSchema, LbEntityPickerUiOptions>( {
        catalogFilterEntryBuilder: convertOpsValues,
        ...props
    })
}

export function convertOpsValues(
    value: Exclude<CatalogFilterQueryValue, Array<any>>,
    formData : any
): string | symbol {
    if (isParameterReferenceFilter(value)) {
        const parameterName : string = value.parameter.toString()
        const parameterValue : string | undefined = formData[parameterName];
        if (parameterValue == undefined) {
            return "§$%&/()THIS_IS_NOT_DEFINED_USE_SOMETHING_ELSE§$%&/()=";
        }

        return parameterValue.toString();
    }
    
    return superConvertOps(value, formData);
}
